<template>
  <div style="text-align: justify">
    <h2 class="mb-4">Standardization of NaOH - Prelab Exercise</h2>

    <p class="mb-6">
      The following data were collected during the titration of a solid sample of potassium acid
      phthalate ("KHP") with a solution of sodium hydroxide. The NaOH solution was added to the KHP
      from a buret.
    </p>

    <p class="mb-2">
      Please enter your exercise number below. Without this number, you will automatically receive a
      grade of 0 on your prelab. If this does not match the number on the prelab you hand in, you
      will receive a grade of 0.
    </p>

    <calculation-input
      v-model="inputs.exerciseNumber"
      class="mb-5"
      prepend-text="$\text{Exercise Number:}$"
      :disabled="!allowEditing"
    />

    <p class="mb-6">
      From the experimental data given below, calculate the <b>molarity</b> of the NaOH solution. Be
      sure to enter your units in the second input field.
    </p>

    <p class="mb-5">
      <u>Data:</u>
    </p>

    <v-simple-table style="margin-left: 5px; margin-bottom: 20px; max-width: 513px">
      <thead>
        <tr>
          <td>Molar mass of potassium acid phthalate</td>
          <td>
            <stemble-latex content="$204.23~\text{g/mol}$" />
          </td>
        </tr>
        <tr>
          <td>Mass of weighing bottle plus KHP</td>
          <td v-if="exNumber >= 101 && exNumber <= 999">
            <latex-number :number="bottleAndKHP" unit="\text{g}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td>Mass of weighing bottle</td>
          <td v-if="exNumber >= 101 && exNumber <= 999">
            <latex-number :number="bottle.toFixed(4)" unit="\text{g}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td>Initial Buret Reading</td>
          <td v-if="exNumber >= 101 && exNumber <= 999">
            <latex-number :number="Vi.toFixed(2)" unit="\text{mL}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
        <tr>
          <td>Final Buret Reading</td>
          <td v-if="exNumber >= 101 && exNumber <= 999">
            <latex-number :number="Vf.toFixed(2)" unit="\text{mL}" />
          </td>
          <td v-else style="font-weight: bold; color: #c50000">Invalid Exercise Number</td>
        </tr>
      </thead>
    </v-simple-table>

    <v-row>
      <v-col cols="5">
        <calculation-input
          v-model="inputs.concNaOH"
          prepend-text="$\ce{[NaOH]:}$"
          :disabled="!allowEditing"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="inputs.units" prefix="Units:" outlined :disabled="!allowEditing" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';
import {seededRandomBetween} from '@/courses/utils/seededShuffle';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UPEINaOHStandardizationPreLabV2',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        exerciseNumber: null,
        concNaOH: null,
        units: null,
      },
    };
  },
  computed: {
    exNumber() {
      return this.inputs.exerciseNumber;
    },
    KHP() {
      return seededRandomBetween(this.exNumber, 1.2, 1.5, 4);
    },
    bottle() {
      return seededRandomBetween(this.exNumber * 2, 21, 23, 4);
    },
    Vi() {
      return seededRandomBetween(this.exNumber * 3, 0.01, 0.5, 2);
    },
    Vf() {
      return seededRandomBetween(this.exNumber * 4, 36, 40, 2);
    },
    bottleAndKHP() {
      return (this.KHP + this.bottle).toFixed(4);
    },
  },
};
</script>
